<template>
  <div>
    <page-header>
      Adherely Subscriptions
      <template slot="interactives">
        <v-btn @click="doLogout">
          Log out
        </v-btn>
      </template>
    </page-header>
    <dashboard-content
      return-to="userSettings"
    >
      <h2>Choose the best option for you</h2>
      <v-btn-toggle
        v-model="option"
        style="margin-top:1rem"
      >
        <v-btn>Monthly</v-btn>
        <v-btn>Annually (save $60)</v-btn>
      </v-btn-toggle>
      <div class="options-wrapper">
        <v-list
          v-for="plan in optionsList"
          id="basic"
          :key="plan.title"
          dense
          class="list"
        >
          <v-list-item-title class="list-title">
            {{ plan.title }} 
            <v-chip
              v-if="plan.popular"
              variant="elevated"
              class="primary-background chip"
            >
              Popular
            </v-chip>
            <v-list-item-subtitle>
              <span>{{ plan.subtitle }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <sub v-if="subscriptionType(plan.title)"> *Current plan</sub>
              <sub v-else>&nbsp;</sub>
            </v-list-item-subtitle>
          </v-list-item-title>
          <hr>
          <v-list-item-title class="price">
            {{ plan.price }}
          </v-list-item-title>
          <v-list-item>
            <router-link
              v-if="plan.action"
              :to="{name: plan.action, hash: plan.hash}"
              style="margin: 0 auto;width: 100%;"
            >
              <v-btn
                class="cta"
                elevation="0"
              >
                {{ plan.cta }}
              </v-btn>
            </router-link>
            <v-btn
              v-else
              class="cta"
              elevation="0"
              @click="openIntercom"
            >
              {{ plan.cta }}
            </v-btn>
          </v-list-item>
          <v-list-item
            v-for="opt in plan.bullets"
            :key="opt"
            class="list-item"
          >
            <v-list-item-icon class="icon">
              <v-icon color="primary">
                {{ mdiCheckCircle }}
              </v-icon>
            </v-list-item-icon>
            {{ opt }}
          </v-list-item>
        </v-list>
      </div>
    </dashboard-content>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardContent from '@/components/dashboard/DashboardContent'
import PageHeader from '@/components/dashboard/PageHeader'
import { mdiCheckCircle } from '@mdi/js'

export default {
  name: 'Subscription',
  components: {
    DashboardContent,
    PageHeader,
  },
  data() {
    return {
      mdiCheckCircle,
      option: 0,
      optionsList: [
        {
          title: 'Free',
          subtitle: 'Free forever. Yes, really!',
          price: 'Free!',
          cta: 'Get started',
          action: 'userSettings',
          hash: '#subscription',
          popular: false,
          bullets: [
            'Library of exercises and assessments',
            'Schedule reminders up to one week',
            'Basic reporting and analytics',
            'Unlimited client profiles',
            'Basic chat and email support'
          ],
        }, {
          title: 'Professional',
          subtitle: 'For individual providers.',
          price: '$120/yr',
          cta: 'Get started',
          action: 'userSettings',
          hash: '#subscription',
          popular: true,
          bullets: [
            'Custom reminders',
            'Automate reminders for the year',
            'Export results',
            'Advanced reporting and analytics',
            'Priority chat and email support'
          ],
        }, {
          title: 'Clinic', 
          subtitle: 'For clinics.',
          price: 'Custom',
          cta: 'Contact us!',
          popular: false,
          bullets: [
            'Advanced custom exercises',
            'Early access to new features',
            'Custom reporting',
            'Mass client upload',
            'Dedicated Account Manager'
          ]
        }
      ],
      prices: [
        '$15 per month',
        '$120 per year'
      ],
    }
  },
  computed: {
    ...mapGetters('authentication', ['user']),
    ...mapGetters('app', ['isLocked']),
    ...mapGetters('subscription', ['subscription']),
  },
  methods: {
    ...mapActions('authentication', ['fetchUser', 'logout']),
    ...mapActions('app', ['unlock']),
    async doLogout() {
      if (this.isLocked) {
        this.unlock()
      }
      if (window.parent) {
        window.parent.postMessage('adherelySessionClosed', '*')
        console.log('window.parent: adherelySessionClosed')
      } else {
        window.postMessage('adherelySessionClosed', '*')
        console.log('window: adherelySessionClosed')
      }
      window.Intercom('shutdown')
      await this.logout()
      await this.$router.replace({ name: 'signIn' })
      // window.location.reload()
    },
    subscriptionType(type) {
      switch (type) {
        case 'Free':
          return !this.subscription || !this.subscription?.plan.active
        case 'Professional':
          return this.subscription && this.subscription?.plan.active
        default:
          return false
      }
    },
    openIntercom() {
      window.Intercom('showNewMessage', 'I\'d like to learn more about the Clinic plan for Adhere.ly')
    }
  }
}
</script>

<style scoped>

hr {
  margin: 1rem auto;
  max-width: calc(100% - 2rem);
}

.cta {
  margin: 2rem auto;
  width: 100%;
}
.options-wrapper {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.list {
  border: 1px solid #EAECF0;
  border-radius: 1rem;
  margin: 1rem;
  max-width: 90%;
}

.list-title {
  border-radius: 1rem 1rem 0 0;
  font-size: 1.5rem;
  min-height: 4rem;
  padding: 0 1rem;
  position: relative;
}

.list-item {
  font-size: 0.875rem;
}

.chip {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.icon {
  margin-right: 12px !important;
}

.price {
  font-size: 3rem;
  font-weight: bold;
  margin-left: 1rem;
  text-align: left;
}

@media(min-width: 1265px) {
  .options-wrapper {
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
  }

  .list {
    min-width: 271px;
    flex-basis: 19rem;
  }
}

@media(min-width: 1440px) {
  .list-item {
    font-size: 0.875rem;
  }
}

</style>